<template>
  <div>
    <chat-window
      height="83vh"
      style="font-family: 'Quicksand', sans-serif;"
      :current-user-id="currentUserId"
      :rooms="rooms"
      :rooms-loaded="roomsLoaded"
      :loading-rooms="loadingRooms"
      :messages="messages"
      :show-new-messages-divider="true"
      :messages-loaded="messagesLoaded"
      :room-actions="[
        {
          name: 'deleteRoom',
          title: 'Delete room',
        },
      ]"
      :menu-actions="dinamicMenuActions"
      :show-reaction-emojis="false"
      :message-actions="dynamicMessageActions"
      :room-info-enabled="true"
      :responsive-breakpoint="900"
      :single-room="false"
      :theme="theme"
      :scroll-distance="300"
      @send-message="sendMessage"
      @fetch-more-rooms="fetchMoreRooms"
      @fetch-messages="fetchMessages"
      @room-action-handler="roomActionHandler"
      @menu-action-handler="menuActionHandler"
      @room-info="roomInfoHandler"
      @delete-message="deleteMessageHandler"
      @open-file="openFileHandler"
      @send-message-reaction="sendMessageReactionHandler"
      @edit-message="editMessage"
      @add-room="addRoomHandler"
    />
    <InviteRemoveUserModal
      :invite-remove-user-room-id="inviteRemoveUserRoomId"
      @updateRoomMembers="updateRoomMembers"
    />
    <CreateRoomModal @CreatedRoom="createRoom" />
  </div>
</template>

<script>
import 'vue-advanced-chat/dist/vue-advanced-chat.css'
import ChatWindow from 'vue-advanced-chat'
import useAppConfig from '@/@core/app-config/useAppConfig'
import useJwt from '@/auth/jwt/useJwt'
import configs from '../../../../axios.config'
import areYouSure from '@/mixins/areYouSure'
import InviteRemoveUserModal from './inviteRemoveUserModal.vue'
import CreateRoomModal from './CreateRoomModal.vue'
import formmatter from '@/mixins/formmatter'

const { skin } = useAppConfig()

export default {
  name: 'Chat',

  components: {
    ChatWindow,
    InviteRemoveUserModal,
    CreateRoomModal,
  },

  mixins: [areYouSure, formmatter],

  data() {
    return {
      currentUserId: '',
      currentUserName: 'Вы',
      currentUserAvatar: '',
      roomsLoaded: true,
      loadingRooms: true,
      messagesLoaded: false,
      messages: [],
      params: {
        page: 1,
        page_size: 30,
      },
      inviteRemoveUserRoomId: [],
      updateRoomMembersRoomId: null,
      next: null,
      dynamicMessageActions: [
        {
          name: 'replyMessage',
          title: 'Reply',
        },
        {
          name: 'editMessage',
          title: 'Edit Message',
          onlyMe: true,
        },
        {
          name: 'deleteMessage',
          title: 'Delete Message',
          onlyMe: true,
        },
      ],
      rooms: [],
      isGroupChat: false,
      // rooms: [
      //   {
      //     roomId: 1,
      //     roomName: 'Room 1',
      //     avatar: require('@/assets/images/avatars/1-small.png'),
      //     lastUpdated: new Date(),
      //     users: [
      //       {
      //         _id: 20,
      //         username: 'John Doe',
      //         avatar: 'assets/images/avatars/1-small.png',
      //         status: {
      //           state: 'online',
      //           lastChanged: 'today, 14:30',
      //         },
      //       },
      //       {
      //         _id: 4321,
      //         username: 'John Snow',
      //         avatar: 'assets/images/avatars/2-small.png',
      //         status: {
      //           state: 'online',
      //           lastChanged: 'today, 14:30',
      //         },
      //       },
      //     ],
      //   },
      // ],
    }
  },

  computed: {
    theme() {
      return skin.value || 'light'
    },
    dinamicMenuActions() {
      if (this.isGroupChat) {
        return [
          {
            name: 'inviteRemoveUser',
            title: 'Invite / Remove User',
          },
          {
            name: 'deleteRoom',
            title: 'Delete Room',
          },
        ]
      }
      return [
        {
          name: 'deleteRoom',
          title: 'Delete Room',
        },
      ]
    },
  },

  mounted() {
    // if (!this.$socket) {
    this.connect()
    // }
    const user = useJwt.getUserData()
    // console.log('user', user)
    this.currentUserId = user.id
    this.currentUserName = user?.username || 'Вы'
    this.currentUserAvatar = user?.photo
      ? this.$http.defaults.baseURL + user.photo
      : ''
    this.createMesageListener()
  },

  beforeDestroy() {
    this.$socket.close()
  },

  methods: {
    createMesageListener() {
      this.$options.sockets.onmessage = async request => {
        const data = await JSON.parse(request.data)
        // const actions = {
        //   new_message: this.onNewMessage(data),
        //   messages: this.onLoadMessages(data),
        //   _send_message: this.onSendMessage(data),
        //   _update_message_content: this.onUpdateMessageContent(data),
        //   _delete_message: this.onDeleteMessage(data),
        //   rooms: this.onDeleteOrFetchRooms(data),
        //   _delete_room: this.onDeleteOrFetchRooms(data),
        //   _update_room: this.onUpdateRoom(data),
        //   _create_room: this.onCreateRoom(data),
        //   _room_changed: this.onRoomChanged(data),
        // }
        // actions[data.action]()
        if (data.action === 'new_message') {
          this.onNewMessage(data)
        } else if (data.action === 'messages') {
          // if (data.data.next === 2) {
          //   this.messages = []
          // }
          this.onLoadMessages(data)
        } else if (
          data.action === '_send_message'
          && data.room === this.currentRoomId
        ) {
          this.onSendMessage(data)
        } else if (
          data.action === '_update_message_content'
          && data.room === this.currentRoomId
        ) {
          this.onUpdateMessageContent(data)
        } else if (
          data.action === '_delete_message'
          && data.room === this.currentRoomId
        ) {
          this.onDeleteMessage(data)
        } else if (data.action === 'rooms' || data.action === '_delete_room') {
          this.onDeleteOrFetchRooms(data)
        } else if (data.action === '_update_room') {
          this.onUpdateRoom(data)
        } else if (data.action === '_create_room') {
          this.onCreateRoom(data)
        } else if (data.action === '_room_changed') {
          this.onRoomChanged(data)
        }
        // this.changeMessageStatus(room, message)
      }
    },
    // ================================= CHAT ACTIONS ======================================

    onNewMessage(data) { // on new message from server
      this.messages = []
      const message = [this.messages, data.data].flat()

      this.$nextTick(() => {
        this.messages = message
      })
    },

    onLoadMessages(data) { // on load messages in room
      this.$nextTick(() => {
        this.messages = [...data.data.result, ...this.messages]
        this.next = data.data.next
        if (!this.next) {
          this.messagesLoaded = true
        }
        if (this.messages.length) {
          const message = this.messages[this.messages.length - 1]
          if (message.senderId !== this.currentUserId) {
            // eslint-disable-next-line no-underscore-dangle
            this.changeMessageStatus(data.room, message._id)
          }
        }
      })
    },

    onSendMessage(data) { // on send message to server
      if (data.room !== this.currentRoomId) return
      const message = [this.messages, data.data].flat()

      this.$nextTick(() => {
        this.messages = message
        if (this.messages.length) {
          const new_message = this.messages[this.messages.length - 1]
          if (new_message.senderId !== this.currentUserId) {
            // eslint-disable-next-line no-underscore-dangle
            this.changeMessageStatus(data.room, new_message._id)
          }
        }
      })
    },

    onUpdateMessageContent(data) { // on update message content to server
      if (data.room !== this.currentRoomId) return
      const message = this.messages.map(item => {
        // eslint-disable-next-line no-underscore-dangle
        if (item._id === data.data._id) {
          // eslint-disable-next-line no-param-reassign
          item = data.data
        }
        return item
      })

      this.$nextTick(() => {
        this.messages = message
      })
    },

    onDeleteMessage(data) { // on delete message to server
      if (data.room !== this.currentRoomId) return
      const message = this.messages.map(item => {
        // eslint-disable-next-line no-underscore-dangle
        if (item._id === data.data._id) {
          // eslint-disable-next-line no-param-reassign
          item = data.data
        }
        return item
      })

      this.$nextTick(() => {
        this.messages = message
      })
    },
    onDeleteOrFetchRooms(data) { // on delete or update room to server
      this.rooms = data.data.sort((a, b) => new Date(b.last_change_time) - new Date(a.last_change_time))
      this.$nextTick(() => {
        this.loadingRooms = false
      })
    },
    onUpdateRoom(data) { // on update room to server
      this.rooms = this.rooms.map(item => {
        if (item.roomId === data.data.roomId) {
          // eslint-disable-next-line no-param-reassign
          item = data.data
        }
        return item
      })
    },
    onCreateRoom(data) { // on create room to server
      // this.roomsLoaded = false
      this.rooms = [...this.rooms, data.data]
      // this.roomsLoaded = true
    },
    onRoomChanged(data) { // on room changed to server
      // this.roomsLoaded = false
      this.rooms = this.rooms.map(e => {
        if (e.roomId === data.data.roomId) {
          return data.data
        }
        return e
      }).sort((a, b) => new Date(b.last_change_time) - new Date(a.last_change_time))
      // this.roomsLoaded = true
    },

    // ================================= CHAT ACTIONS END ======================================

    // ================================= CONNECTION ======================================
    async connect() { // connect to websocket
      await this.$connect(`${configs.webSocket}/?token=${useJwt.getToken()}`, {
        reconnection: false,
        reconnectionAttempts: 5,
        reconnectionDelay: 3000,
        format: 'json',
      })
    },
    // ================================= CONNECTION END ======================================

    fetchMessages({ room, options }) { // load messages in room
      if (this.currentRoomId !== room.roomId) {
        this.messagesLoaded = false
        this.$set(this.params, 'page', 1)
        this.messages = []
      }
      if (options === undefined) {
        if (this.next) {
          this.params.page = this.next
        }
      }
      if (room) {
        this.currentRoomId = room.roomId
        this.isGroupChat = room.is_group
        this.$socket.sendObj({
          action: 'get_messages_by_room',
          pk: room.roomId,
          request_id: new Date().getTime(),
          ...this.params,
        })
      }
      // setTimeout(() => {
      //   if (options.reset) {
      //     this.messages = this.addMessages(true)
      //   } else {
      //     this.messages = [...this.addMessages(), ...this.messages]
      //     this.messagesLoaded = true
      //   }
      //   // this.addNewMessage()
      // })
    },

    changeMessageStatus(room, message) { // change message status to read
      this.$socket.sendObj({
        action: 'read_message',
        pk: room,
        request_id: new Date().getTime(),
        message_id: message,
        is_read: true,
      })
    },

    // addMessages(reset) {
    //   const messages = []

    //   for (let i = 0; i < 30; i++) {
    //     messages.push({
    //       _id: reset ? i : this.messages.length + i,
    //       content: `${reset ? '' : 'paginated'} message ${i + 1}`,
    //       senderId: 4321,
    //       username: 'John Doe',
    //       avatar: require('@/assets/images/avatars/1-small.png'),
    //       date: '13 November',
    //       timestamp: '10:20',
    //       system: false,
    //       saved: true,
    //       distributed: true,
    //       seen: true,
    //       deleted: false,
    //       failure: true,
    //       disableActions: false,
    //       disableReactions: false,
    //       files: [],
    //       reactions: {},
    //     })
    //     if (i === 29) {
    //       messages[i].files.push({
    //         name: 'My File',
    //         size: 67351,
    //         type: 'png',
    //         audio: true,
    //         duration: 14.4,
    //         url:
    //           'https://firebasestorage.googleapis.com/v0/b/vuexfire-a9c43.appspot.com/o/files%2F6R0MijpK6M4AIrwaaCY2%2F5RM2yf2TBCVmAUpaHbCD%2Fimage.png?alt=media&token=141dc7d8-1665-438b-871e-6e8566fabd2c',
    //         preview:
    //           'https://firebasestorage.googleapis.com/v0/b/vuexfire-a9c43.appspot.com/o/files%2F6R0MijpK6M4AIrwaaCY2%2F5RM2yf2TBCVmAUpaHbCD%2Fimage.png?alt=media&token=141dc7d8-1665-438b-871e-6e8566fabd2c',
    //       })
    //     }
    //   }

    //   return messages
    // },

    // sendMessage(message) {
    //   const newMessage = {
    //     _id: this.messages.length,
    //     content: message.content,
    //     senderId: this.currentUserId,
    //     username: 'John Doe',
    //     avatar: require('@/assets/images/avatars/2-small.png'),
    //     date: new Date().toDateString(),
    //     timestamp: new Date().toString().substring(16, 21),
    //     system: false,
    //     saved: true,
    //     distributed: false,
    //     seen: false,
    //     deleted: false,
    //     failure: false,
    //     disableActions: false,
    //     disableReactions: true,
    //     files: [
    //       // {
    //       //   name: 'My File',
    //       //   size: 67351,
    //       //   type: 'png',
    //       //   audio: true,
    //       //   duration: 14.4,
    //       //   url: 'https://firebasestorage.googleapis.com/...',
    //       //   preview: 'data:image/png;base64,iVBORw0KGgoAA...',
    //       //   progress: 88,
    //       // },
    //     ],
    //   }
    //   if (message.files) {
    //     newMessage.files = this.formattedFiles(message.files)
    //   }
    //   this.messages = [...this.messages, newMessage]
    // },

    async sendMessage({ // send message to server
      content, roomId, files, replyMessage,
    }) {
      const message = {
        // _id: 18,
        action: 'send_message',
        pk: roomId,
        request_id: new Date().getTime(),

        senderId: this.currentUserId,
        username: this.currentUserName,
        avatar: this.currentUserAvatar,
        content,
        date: new Date().toDateString(),
        timestamp: new Date(),
        system: false,
        saved: true,
        distributed: true,
        seen: true,
        deleted: false,
        failure: true,
        disableActions: false,
        disableReactions: true,
        reactions: null,
      }
      if (replyMessage) {
        message.replyMessage = {
          // eslint-disable-next-line no-underscore-dangle
          _id: replyMessage._id,
          content: replyMessage.content,
          senderId: replyMessage.senderId,
        }
        if (replyMessage.files) {
          message.replyMessage.files = replyMessage.files
        }
      }
      if (files) {
        // this.$set(message, 'files', this.formattedFiles(files))
        // console.log(message)
        // message.files = await
        this.formattedFiles(message, files)
      } else {
        this.$socket.sendObj(message)
      }
      // console.log(typeof message.files, 'files')
      // const { id } = await firestoreService.addMessage(roomId, message)
      // if (files) {
      //   for (let index = 0; index < files.length; index++) {
      //     await this.uploadFile({ file: files[index], messageId: id, roomId })
      //   }
      // }
      // this.messages = [...this.messages, message]
      // updateRoom(roomId, { lastUpdated: new Date() })
      // this.$socket.sendObj(message)
    },
    async formattedFiles(message, files) {
      // const formattedFiles = []
      await files.forEach(async file => {
        // const messageFile = {
        //   name: file.name,
        //   // size: file.size,
        //   // type: file.type,
        //   // extension: file.extension || file.type,
        //   url: file.blob,
        // }
        this.$_convertToBase64(file.blob).then(res => {
          // messageFile.url = res
          const fileMessage = message
          fileMessage.url = res
          fileMessage.name = file.name
          if (file.audio) {
            fileMessage.audio = true
            fileMessage.duration = file.duration
          }
          this.$socket.sendObj(fileMessage)
        })
        // formattedFiles.push(messageFile)
      })
      // console.log(formattedFiles, formattedFiles.length)
      // return formattedFiles
    },
    inviteRemoveUser(roomId) {
      this.updateRoomMembersRoomId = roomId
      const currentRoomMembers = this.rooms.map(room => {
        if (room.roomId === roomId) {
          // eslint-disable-next-line no-underscore-dangle
          console.log(room.users)
          return room.users?.map(({ _id, ...rest }) => ({
            id: _id,
            ...rest,
          }))
        }
        return []
      }).filter(e => e !== undefined).flat()

      this.inviteRemoveUserRoomId = currentRoomMembers
      this.$bvModal.show('inviteRemoveUserModal')
      // this.$socket.sendObj({
      //   action: 'invite_remove_user',
      //   pk: roomId,
      //   request_id: new Date().getTime(),
      //   userId,
      // })
    },

    async editMessage({ // edit message to server
      messageId, newContent, roomId, files,
    }) {
      const newMessage = { edited: new Date() }
      newMessage.content = newContent
      if (files) {
        newMessage.files = this.formattedFiles(files)
      } else {
        newMessage.files = []
      }
      // await firestoreService.updateMessage(roomId, messageId, newMessage)
      // if (files) {
      //   for (let index = 0; index < files.length; index++) {
      //     if (files[index]?.blob) {
      //       await this.uploadFile({ file: files[index], messageId, roomId })
      //     }
      //   }
      // }

      this.$socket.sendObj({
        action: 'update_message_content',
        pk: roomId,
        request_id: new Date().getTime(),
        message_id: messageId,
        content: newMessage.content,
      })
    },

    deleteRoom(roomId) {
      this.$socket.sendObj({
        action: 'delete_room',
        pk: roomId,
        request_id: new Date().getTime(),
      })
    },

    updateRoomMembers(members) {
      console.log('updateRoomMembers', this.updateRoomMembersRoomId, members)
      this.$socket.sendObj({
        action: 'create_room',
        pk: this.updateRoomMembersRoomId,
        request_id: new Date().getTime(),
        members,
        is_group: true,
      })
    },

    createRoom(room) {
      this.$socket.sendObj(room)
    },

    // async deleteMessage({ message, roomId }) {
    //   // await firestoreService.updateMessage(roomId, message._id, {
    //   //   deleted: new Date(),
    //   // })

    // },

    // async uploadFile({ file, messageId, roomId }) {
    //   return new Promise(resolve => {
    //     let type = file.extension || file.type
    //     if (type === 'svg' || type === 'pdf') {
    //       type = file.type
    //     }
    //     storageService.listenUploadImageProgress(
    //       this.currentUserId,
    //       messageId,
    //       file,
    //       type,
    //       progress => {
    //         this.updateFileProgress(messageId, file.localUrl, progress)
    //       },
    //       _error => {
    //         resolve(false)
    //       },
    //       async url => {
    //       // const message = await firestoreService.getMessage(roomId, messageId)
    //         message.files.forEach(f => {
    //           if (f.url === file.localUrl) {
    //             f.url = url
    //           }
    //         })
    //         // await firestoreService.updateMessage(roomId, messageId, {
    //         //   files: message.files,
    //         // })
    //         resolve(true)
    //       },
    //     )
    //   })
    // },
    async fetchMoreRooms(e) {
      console.log('title', e)
    },

    roomActionHandler({ roomId, action }) {
      this.actionType(action.name, roomId)
    },

    menuActionHandler({ roomId, action }) {
      this.actionType(action.name, roomId)
    },

    actionType(action, roomId) {
      if (action === 'deleteRoom') {
        this.$_showAreYouSureModal()
          .then(result => {
            if (result) this.deleteRoom(roomId)
          })
      } else if (action === 'inviteRemoveUser') {
        this.inviteRemoveUser(roomId)
      }
    },

    messageActionHandler({ roomId, action, message }) {
      console.log('messageActionHandler', roomId, action, message)
    },

    // messageSelectionActionHandler({ roomId, action, messages }) {
    messageSelectionActionHandler({ action, message }) {
      console.log('messageSelectionActionHandler', action, message)
    },

    roomInfoHandler(room) {
      console.log('roomInfoHandler', room)
    },

    addRoomHandler() {
      this.$bvModal.show('CreateRoomModal')
    },

    deleteMessageHandler({ roomId, message }) {
      this.$socket.sendObj({
        action: 'delete_message',
        pk: roomId,
        request_id: new Date().getTime(),
        // eslint-disable-next-line no-underscore-dangle
        message_id: message._id,
      })
    },

    openFileHandler({ message, file }) {
      fetch(file.file.url)
        .then(resp => resp.blob())
        .then(blobobject => {
          const blob = window.URL.createObjectURL(blobobject)
          const anchor = document.createElement('a')
          anchor.href = blob
          anchor.setAttribute('download', file.file.name)
          anchor.click()
        })
      // this.audio = `${window.URL.createObjectURL(file.url)}#t=3,5`
      // const blobUrl = window.URL.createObjectURL(file.url)
      // const link = document.createElement('a') // Or maybe get it from the current document
      // link.href = `data:image/jpeg;base64,${file.file.url}`
      // link.setAttribute('download', file.file.name)
      // link.target = '_blank'
      // link.click()
      console.log('openFileHandler', message, file)
    },

    sendMessageReactionHandler({
      roomId, messageId, reaction, remove,
    }) {
      console.log(
        'sendMessageReactionHandler',
        roomId,
        messageId,
        reaction,
        remove,
      )
      this.messages.map(message => {
        // eslint-disable-next-line no-underscore-dangle
        if (message._id === messageId) {
          if (remove) {
            const a = message.reactions.reaction.filter(
              r => r !== this.currentUserId,
            )
            // eslint-disable-next-line no-param-reassign
            message.reactions.reaction = a
          } else if (!message.reactions.includes(reaction)) {
            // eslint-disable-next-line no-param-reassign
            message.reactions.reaction = [
              ...message.reactions.reaction,
              this.currentUserId,
            ]
          } else {
            // eslint-disable-next-line no-param-reassign
            message.reactions.reaction = [this.currentUserId]
          }
        }
        return message
      })
    },
  },
}
</script>
