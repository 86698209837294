<template>
  <b-modal
    id="inviteRemoveUserModal"
    title="Удалить / добавить пользователей"
    size="lg"
    no-close-on-backdrop
    ok-title="Обновить"
    cancel-title="Отменить"
    cancel-variant="danger"
    ok-variant="success"
    @ok="onSubmit"
    @show="onShow"
  >
    <validation-observer ref="infoClient">
      <b-row>
        <b-col md="12">
          <b-form-group
            label="Участники*"
            label-for="name"
          >
            <validation-provider
              #default="{ errors }"
              name="Участники"
              rules="required"
            >
              <AppSelect
                v-model="users"
                class="bg-white"
                :get-option-label="label=>label.full_name"
                placeholder="Выберите участников"
                :lazy-fetch-data="FETCH_USERS_ASYNC"
                :options="getUsersAsync"
                :reduce="(user) => user.id"
                :filters="{ chat: true }"
                multiple
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal, BRow, BCol, BFormGroup,
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { mapActions, mapGetters } from 'vuex'
import AppSelect from '@/components/AppSelect.vue'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BFormGroup,
    // BFormInput,
    ValidationProvider,
    ValidationObserver,
    AppSelect,
  },

  props: {
    inviteRemoveUserRoomId: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      required,
      users: [],
    }
  },

  computed: {
    ...mapGetters('users', ['getUsersAsync']),
  },
  watch: {
    inviteRemoveUserRoomId(val) {
      this.users = val
    },
  },
  mounted() {

  },
  methods: {
    ...mapActions('users', [
      'FETCH_USERS_ASYNC',
    ]),
    onSubmit() {
      this.$refs.infoClient.validate().then(valid => {
        if (valid) {
          this.$emit('updateRoomMembers', this.users.map(e => e.id))
        }
      })
    },
    onShow() {
      // this.users = this.inviteRemoveUserRoomId
    },
  },
}
</script>
