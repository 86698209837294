<template>
  <b-modal
    id="CreateRoomModal"
    title="Удалить / добавить пользователей"
    size="md"
    no-close-on-backdrop
    ok-title="Обновить"
    cancel-title="Отменить"
    cancel-variant="danger"
    ok-variant="success"
    @ok="onSubmit"
    @show="onShow"
  >
    <validation-observer ref="infoClient">
      <!-- This tabs content will always be mounted -->
      <b-button-group class="my-2">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          :variant="!is_group ? 'primary' : 'outline-primary'"
          @click="is_group = false"
        >
          Создать комнату
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          :variant="is_group ? 'primary' : 'outline-primary'"
          @click="is_group = true"
        >
          Создать группу
        </b-button>
      </b-button-group>

      <b-row v-if="!is_group">
        <b-col md="12">
          <b-form-group
            label="Клиент*"
            label-for="name"
          >
            <validation-provider
              #default="{ errors }"
              name="Клиент"
              rules="required"
            >
              <!-- <AppSelect
                v-model="user"
                class="bg-white"
                label="first_name"
                placeholder="Выберите участников"
                :lazy-fetch-data="FETCH_USERS_FOR_CHAT"
                :options="getUsersForChatAsync"
                :reduce="(user) => user.id"
              /> -->
              <v-select
                v-model="user"
                class="bg-white"
                label="first_name"
                placeholder="Выберите пользователя"
                :options="usersLisOptions"
                :reduce="(user) => user.id"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>

      <!-- This tabs content will not be mounted until the tab is shown -->
      <!-- and will be un-mounted when hidden -->

      <b-row v-else>
        <b-col md="12">
          <div class="text-center">
            <b-img
              class="mb-1"
              style="height: 152px"
              thumbnail
              fluid
              :src="photo"
              alt="Фото ребенка"
            />
          </div>

          <b-form-file
            v-model="avatarFile"
            class="mb-1"
            accept=".jpg, .png"
            :browse-text="$t('general.upload')"
            :placeholder="$t('general.select_file')"
            :drop-placeholder="$t('general.select_file')"
            @input="toBase"
          />
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Название группы*"
            label-for="name"
          >
            <validation-provider
              #default="{ errors }"
              name="Название группы"
              rules="required"
            >
              <b-form-input
                id="title"
                v-model="roomName"
                placeholder="Введите названию группы"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Участники*"
            label-for="name"
          >
            <validation-provider
              #default="{ errors }"
              name="Участники"
              rules="required"
            >
              <AppSelect
                v-model="users"
                class="bg-white"
                :get-option-label="label=>label.full_name"
                placeholder="Выберите участников"
                :lazy-fetch-data="FETCH_USERS_ASYNC"
                :options="getUsersAsync"
                :filters="{ chat: true }"
                multiple
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BButtonGroup,
  BImg,
  BFormFile,
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { mapActions, mapGetters } from 'vuex'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import AppSelect from '@/components/AppSelect.vue'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    AppSelect,
    vSelect,
    BButton,
    BButtonGroup,
    BImg,
    BFormFile,
  },

  directives: {
    Ripple,
  },

  data() {
    return {
      required,
      usersLisOptions: [],
      users: [],
      roomName: '',
      is_group: false,
      user: null,
      avatarFile: null,
      avatar: null,
    }
  },

  mounted() {},

  computed: {
    ...mapGetters('users', ['getUsersAsync', 'getUsersForChatAsync']),
    photo() {
      return (
        this.avatar
        || 'https://cdn.iconscout.com/icon/free/png-256/account-avatar-profile-human-man-user-30448.png'
      )
    },
  },

  methods: {
    ...mapActions('users', ['FETCH_USERS_ASYNC', 'FETCH_USERS_FOR_CHAT']),
    onSubmit() {
      this.$refs.infoClient.validate().then(valid => {
        if (valid) {
          let room = null
          if (this.is_group) {
            room = {
              action: 'create_room',
              request_id: new Date().getTime(),
              roomName: this.roomName,
              members: this.users.map(e => e.id),
              is_group: this.is_group,
              avatar: this.avatar,
            }
          } else {
            room = {
              action: 'create_room',
              request_id: new Date().getTime(),
              members: [this.user],
              is_group: this.is_group,
            }
          }
          this.$emit('CreatedRoom', room)
        }
      })
    },
    onShow() {
      // this.users = this.inviteRemoveUserRoomId
      this.users = []
      this.roomName = ''
      this.is_group = false
      this.user = null
      this.avatarFile = null
      this.avatar = null
      this.FETCH_USERS_FOR_CHAT().then(res => {
        this.usersLisOptions = res
      })
    },
    toBase() {
      const reader = new FileReader()
      reader.readAsDataURL(this.avatarFile)
      // eslint-disable-next-line no-return-assign
      reader.onload = () => (this.avatar = reader.result)
      reader.onerror = error => console.error(error)
    },
  },
}
</script>
